import emitter from "tiny-emitter/instance";
$(document).ready(loadContent);
var loading_content = false;

function loadContent() {
  if (loading_content || $("[data-load]").length < 1) {
    return;
  }

  loading_content = true;
  Promise.all($("[data-load]").get().map(elemContentLoader)).then(function () {
    loading_content = false;
    loadContent();
    drawWidgets();
    emitter.emit("ajax-loaded");
  });
}

function drawWidgets() {
  const memberWorkGraph = window.memberWorkGraph;
  const memberTeamGraph = window.memberTeamGraph;
  if (typeof memberWorkGraph === "function") {
    $("[data-graph=member-work]").each(memberWorkGraph);
  }

  if (typeof memberTeamGraph === "function") {
    $("[data-graph=member-teamwork]").each(memberTeamGraph);
  }
}

function get_loading_indicator() {
  return "<div class='loading-indicator'>Loading</div>";
}

function elemContentLoader(elem) {
  return new Promise(function (resolve) {
    var me = $(elem);

    me.html(get_loading_indicator());
    resolve(me);
  }).then(function (elem) {
    const COHORT_ID = $("#mtsData").data("cohort-id");
    const COHORT_SURVEY_ID = $("#mtsData").data("cohort-survey-id");
    let url = elem
      .data("load")
      .replace("COHORT_ID", COHORT_ID)
      .replace("SURVEY_ID", COHORT_SURVEY_ID);
    return ajaxReplace(elem, url);
  });
}

function ajaxReplace(elem, url) {
  return Promise.resolve($.ajax({ url: url, datatype: "html" }))
    .then(function (data) {
      elem = elem.replaceWith(data);
      $("input:checked")
        .add("input[checked=checked]")
        .each(function () {
          $(this).parents("li").addClass("checked");
        });
      return elem;
    })
    .catch(function (err) {
      console.log(err.status, err.statusText);
      return elem.remove();
    });
}
